export default {
    aws: 
    {
        id: 1,
        imgsrc: "AWS.png",
        title: "AWS",
        text: "Large scale, deeply technical AWS capability remains ApexOps' core focus and value proposition. \
        From migrating datacentres to building classified, API driven Serverless applications and helping customers \
        embrace cutting edge cloud technology, there wasn't much we weren't hands on with in 2024. Our ability to deliver \
        world class cloud solutions is largely driven by the incredible AWS experience across every one of our staff, at all levels. \
        "
    },
    azure:
    {
        id: 2,
        imgsrc: "Azure.png",
        title: "Azure",
        text:"Our first large 2021 delivery was an enterprise scale big data migration in partnership with Microsoft Australia. \
        Our mandate was broad, encompassing the initial technology strategy through to design and architecture, network and infrastructure \
        deployment, cybersecurity, monitoring, internal engagement and training across multiple teams, post-delivery hypercare support \
        and optimisation. To achieve the outcome we did, we partnered with multiple Azure teams including Microsoft's Fast Track Australian team, \
        local Azure product managers and global Azure specialists headquartered in Europe."
    },
    sap:
    {
        id: 3,
        imgsrc: "SAP.jpg",
        title: "SAP",
        text:"Migrating multiple datacentres which contain business critical data and capability to cloud can be a daunting proposition for the \
        most experienced teams. Best practice frameworks conflict across vendors,  application and infrastructure footprints are usually \
        highly complex, and there are often dozens, if not hundreds of integration points. \
        From strategy, to initial design and architecture, to building and delivering incredible SAP capability in what ultimately resulted \
        in one of the largest and most successful public sector Australian SAP migrations of 2021, we've been able to assist multiple \
        customers with the know-how, great people, and most importantly: proven results."
    },
  }
  