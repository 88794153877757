<template>
    <div>
        <v-container style="margin: 0px; padding: 0px; width: 100%">
          
            <v-card elevation="0" class="header">
                <v-card-text>
                  <font class="textxxlarge"><font size="+3">Technological transformation made easier through project excellence</font></font>
                </v-card-text>
                
                <v-card-text>
                  <font class="textxlarge">
                      "...............{{capabilities['projects'].text}}"</font>
                      <br><br>
                </v-card-text>
            </v-card>
            
            <v-row no-gutters width="100%" 
                justify="center"
                align="top">
                <v-col md="6" xs=12>
                    <v-card class="card">

                      <v-card-title class="textlarge">
                        Key 2024 Findings
                      </v-card-title>
                      <v-card-title class="textmed" style="word-break: break-word">
                        Focussed Customer Engagement
                      </v-card-title>
                      <v-card-text>
                        <font class="text">
                        Broad engagement and regular reporting continued to be key priorities for our customers in 2025.
                        </font>
                      </v-card-text>
                      
                      <v-card-title class="textmed" style="word-break: break-word">
                        Strong Governance and Controls
                      </v-card-title>
                      <v-card-text>
                        <font class="text">
                        Documented, socialised and agreed project and risk controls were critical.
                        </font>
                      </v-card-text>
                      
                      <v-card-title class="textmed" style="word-break: break-word">
                        Delivery Flexibility
                      </v-card-title>
                      <v-card-text>
                        <font class="text justify">
                        Being sufficiently agile to pivot quickly in response to changes of internal direction or external circumstances.
                        </font>
                      </v-card-text>

                    </v-card>

                </v-col>
                <v-col sm="6" class="hidden-xs-and-down" style='padding-left:20px'>
                  <v-card elevation="0">
                    <v-img
                    :src="require('@/assets/images/projects/pm.jpeg')"
                    contain
                    position="center"
                    height="450px"
                    /></v-card>
                </v-col>
            </v-row>
        
        </v-container>
    </div>
</template>


<script>
  import capabilities from '@/content/capability/capabilities.js'

  export default {
    name: 'Capabilities',
    props: {
        capability: String
    },
    data() {
      return {
        capabilities,
      }
    }
  }
</script>



<style scoped>

.header {
  color: var(--v-textprimary-base) !important;
  background: var(--v-bgprimary-base)  !important;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 0px;
  margin-bottom: 50px;
}

.card {
  background: var(--v-bgprimary-base)  !important;
}

.textxxlarge {
  color: var(--v-textprimary-base) !important;
  font-size: xx-large;
  line-height: 150%;
}

.textxlarge {
  color: var(--v-textprimary-base) !important;
  font-size:  x-large; overflow-wrap: normal;
  font-style: italic;
}

.textmed {
  color: var(--v-textprimary-base) !important;
  font-size: large;
}

.text {
  color: var(--v-textprimary-base) !important;
  font-size: medium;
}

</style>