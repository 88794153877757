export default {
    finance: 
    {
        id: 1,
        imgsrc: "finance.jpeg",
        title: "Finance",
        text: "Working with Australia’s premier payments provider and an Australian big 4 bank, we’re bringing evolutionary payments capability to the local market in Q3 2022. \
        At its core, it’s a full stack serverless application build to support cutting edge IoT devices. The project truly \
        spans the technology footprint end-to-end, incorporating the design and build of everything from encryption algorithms and authentication processes \
        to API driven local mobility applications, to highly redundant high performance data management and a full UI management platform."
    },
    it:
    {
        id: 2,
        imgsrc: "it.jpeg",
        title: "Information Technology & Consulting",
        text:"ApexOps is trusted by  several world class Consulting and Technology organisations to build and deliver complex technology solutions \
        on time and at scale. In 2024 we provided the strategic technical leadership required consolidate \
        the IT environments of multiple NYSE listed organisations, into a single ecosystem by working with partners here and in the USA."
    },
    utilities:
    {
        id: 3,
        imgsrc: "utilities.jpeg",
        title: "Utilities",
        text:"From securing regulatory funding to building physical networks and integrating critical utilities \
        infrastructure with cloud, ApexOps has been able to deliver some truly incredible projects in the electricity and gas markets \
        recently. Whether you need to better secure OT assets, meet AEMO cybersecurity standards or streamline significant \
        amounts of customer data at scale, we understand the inherently unique industry challenges facing modern energy providers."
    },
    defence:
    {
        id: 4,
        title: "Defence, Police and Essential Services",
        text: "A number of ApexOps' staff are ex-Military and/or hold significant experience working in Policing and Emergency Services, \
        and hold most security clearances required to work in these sectors. For further information please contact us directly."
    },
    events:
    {
        id: 5,
        imgsrc: "events.jpeg",
        title: "Events and Entertainment",
        text:"Pulling together a diverse range of short lived technologies at scale does not come without risks and challenges, \
        and it's critical for the supporting technology which powers these incredible experiences to work perfectly when called upon. \
        Over the last year we've been fortunate to work with Australia's premier events and entertainment technology organisation to \
        help rebuild payment systems, migrate full stack cloud environments to entirely serverless platforms, and introduce new IoT \
        technologies to the industry."
    },
    govt:
    {
        id: 6,
        imgsrc: "govt.jpeg",
        title: "Public Sector",
        text:"Much of what we've accomplished has involved helping local, state and federal departments and agencies embrace \
        technological change and realise some incredible achievements. From helping build a successful strategic vision, to regulatory compliance, \
        to architecture and delivery, we're there every step of the way help you navigate your journey from challenge to achievement."
    },
    health:
    {
        id: 7,
        imgsrc: "health.jpeg",
        title: "Health",
        text:"Health is a remarkable sector in which so much good has been accomplished with the aid of modern technology. \
        It presents opportunities to enable positive change in the way we live and improves our lives tremendously. \
        The opportunity for technology to provide better patient experiences, solve highly complex systemic challenges or bring \
        clarity to huge amounts of data is easily supported by the extensive and rapidly growing library of modern technologies \
        made available within our reach. "
    },
  }
  